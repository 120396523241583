import { getParsedEthersError } from "@enzoferey/ethers-error-parser";
import { EthersError } from "@enzoferey/ethers-error-parser/dist/types";
import { enqueueSnackbar } from "notistack";
// const networkErrorRegex = /-errors-(.*) ]/;

// TODO: Handle all errors from https://github.com/enzoferey/ethers-error-parser#return-value

// const metamaskErrors: Record<string, string> = {
//   UserRejectedRequestError: "Operation rejected by user",
// };

// const ethersErrors: Record<string, string> = {
//   DEFAULT: "Error",
//   INSUFFICIENT_OUTPUT_AMOUNT: "Insufficient balance",
//   INSUFFICIENT_A_AMOUNT: "Insufficient input balance",
//   EXPIRED:
//     "Transaction expired. Try again or increase the transaction deadline.",
//   CALL_EXCEPTION: "Transaction failed",
//   UNPREDICTABLE_GAS_LIMIT: "",
// };

const readableTitle: Record<string, string> = {
  EXECUTION_REVERTED: "Transaction reverted.",
  CALL_REVERTED: "Transaction reverted.",
};

const readableDescription: Record<string, string> = {
  "UniswapV2Router: INSUFFICIENT_A_AMOUNT":
    "Insufficient amount of input token. Try increasing your slippage tolerance.",
  "UniswapV2Router: INSUFFICIENT_B_AMOUNT":
    "Insufficient amount of output token. Try increasing your slippage tolerance.",
  "UniswapV2Library: INSUFFICIENT_LIQUIDITY": "Insufficient liquidity",
};

export const getUserErrorMessage = (error: EthersError) => {
  const parsedError = getParsedEthersError(error);

  const title = readableTitle[parsedError.errorCode] || parsedError.errorCode;
  const description = parsedError?.context
    ? readableDescription[parsedError.context] || parsedError.context
    : undefined;

  return { title, description };
};

export const handleErrorMessage = (
  error: EthersError,
  informUser: boolean = true
) => {
  console.error("[error]", error);

  if (!informUser) return;

  const { title, description } = getUserErrorMessage(error);

  enqueueSnackbar(title, {
    variant: "error",
    description,
  });
};
