import { erc20ABI } from "wagmi";
import { factoryABI } from "./factory";
import { pairABI } from "./pair";
import { routerABI } from "./router";
import { stakingABI } from "./staking";

export const ABIS = {
  ERC20: erc20ABI,
  ROUTER: routerABI,
  FACTORY: factoryABI,
  PAIR: pairABI,
  STAKING: stakingABI,
};
