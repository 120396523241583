import { BigNumber } from "ethers";
import { commify, formatUnits } from "ethers/lib/utils";

export const formatBalance = ({
  value,
  decimals,
  tokenSymbol,
  digits = 4,
}: {
  value?: BigNumber;
  decimals?: number;
  tokenSymbol?: string;
  digits?: number;
}): string => {
  if (!value) {
    return tokenSymbol ? `0 ${tokenSymbol}` : "0";
  }

  let formatted = formatUnits(value, decimals);
  formatted = (+formatted).toFixed(digits);

  return tokenSymbol
    ? `${commify(formatted)} ${tokenSymbol}`
    : commify(formatted);
};
