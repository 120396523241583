import { Token } from "core/types";

export const findTokenBySymbol = (tokens?: Token[], symbol?: string) => {
  if (!tokens || !symbol) return undefined;

  return tokens.find(
    (token) => token.symbol.toLowerCase() === symbol.toLowerCase()
  );
};

export const findTokenByAddress = (tokens?: Token[], address?: string) => {
  if (!tokens || !address) return undefined;

  return tokens.find(
    (token) => token.address.toLowerCase() === address.toLowerCase()
  );
};
