import { activeStakingPools } from "constants/staking";
import { useEffect, useState } from "react";
import { useGlobalContext } from "src/_contexts/global/hooks";

export const useActiveStakingPools = () => {
  const { chainID } = useGlobalContext();

  const [pools, setPools] = useState<typeof activeStakingPools>([]);

  useEffect(() => {
    setPools(activeStakingPools.filter((pool) => pool.chainID === chainID));
  }, [chainID]);

  return pools;
};
