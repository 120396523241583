import { Hash } from "@wagmi/core";
import { StakingPool } from "constants/staking";
import { useEffect, useState } from "react";
import { useGlobalContext } from "src/_contexts/global/hooks";
import { useActiveStakingPools } from "./active-staking-pools";

export const useStakingPool = (address?: Hash) => {
  const { chainID } = useGlobalContext();
  const activeStakingPools = useActiveStakingPools();

  const [pool, setPool] = useState<StakingPool | undefined>(undefined);

  useEffect(() => {
    if (!address) {
      setPool(undefined);
    } else {
      const stakingPool = activeStakingPools.find(
        (stakingPool) => stakingPool.stakingAddress === address
      );

      setPool(stakingPool);
    }
  }, [activeStakingPools, address, chainID]);

  return pool;
};
