import { Hash } from "@wagmi/core";

export type StakingPool = {
  stakeID: number;
  chainID: number;
  token0: string;
  token1: string;
  stakingAddress: Hash;
};

export const activeStakingPools: StakingPool[] = [
  {
    stakeID: 1,
    chainID: 80001,
    token0: "FID",
    token1: "USDC",
    stakingAddress: "0x9aC294C9f9dE9Af13A4aEecAdca3a3e3fe8e49d9",
  },
  {
    stakeID: 1,
    chainID: 137,
    token0: "FID",
    token1: "USDC",
    stakingAddress: "0x9f55607444E9366A25CD7A3Fecb92600192d516A",
  },
];
