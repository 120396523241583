import { AddressZero, Zero } from "@ethersproject/constants";
import { QueryObserverResult } from "@tanstack/react-query";
import { Hash } from "@wagmi/core";
import { ABIS } from "constants/abis";
import { Token } from "core/types";
import { BigNumber } from "ethers";
import { useGlobalContext } from "src/_contexts/global/hooks";
import { useContractRead } from "wagmi";
import { useTokenByAddress } from "_hooks/token";
import { useStakingPool } from "./staking-pool";

export const useStakingTokens = (
  stakingAddress?: Hash
): [
  Token | undefined,
  Token | undefined,
  Hash | undefined,
  BigNumber | undefined,
  () => Promise<QueryObserverResult<BigNumber, Error>>
] => {
  const { address } = useGlobalContext();
  const stakingPool = useStakingPool(stakingAddress);

  // TODO: useStakingPool returns token symbols, we don't have to read them from the network

  const { data: pairAddress } = useContractRead({
    address: stakingPool?.stakingAddress ?? AddressZero,
    abi: ABIS.STAKING,
    functionName: "stakingToken",
    enabled: Boolean(stakingPool?.stakingAddress),
  });

  const { data: token0Address } = useContractRead({
    address: pairAddress,
    abi: ABIS.PAIR,
    functionName: "token0",
    enabled: !!pairAddress,
  });

  const { data: token1Address } = useContractRead({
    address: pairAddress,
    abi: ABIS.PAIR,
    functionName: "token1",
    enabled: !!pairAddress,
  });

  const { data: balance, refetch: refetchBalance } = useContractRead({
    address: stakingAddress ?? AddressZero,
    abi: ABIS.STAKING,
    functionName: "stakedAmount",
    args: [
      address ?? AddressZero,
      stakingPool?.stakeID ? BigNumber.from(stakingPool.stakeID) : Zero,
    ],
    enabled: Boolean(address && stakingAddress && stakingPool),
  });

  const token0 = useTokenByAddress(token0Address);
  const token1 = useTokenByAddress(token1Address);

  return [token0, token1, pairAddress, balance, refetchBalance];
};
