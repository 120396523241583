import { Footer } from "components/Footer";
import { NavBar } from "components/NavBar";
import { NotSupportedChain } from "components/NotSupportedChain";
import { useIsMounted } from "hooks/isMounted";
import React from "react";
import { useGlobalContext } from "src/_contexts/global/hooks";

type Props = {
  children?: React.ReactNode;
};

export const Layout: React.FC<Props> = ({ children }) => {
  const isMounted = useIsMounted();
  const { isChainSupported } = useGlobalContext();

  return (
    <div className="grid h-full min-h-screen w-full grid-rows-[auto_1fr_auto] gap-4 bg-background">
      <div className="fixed inset-0 z-[1] bg-noise opacity-60 bg-blend-hard-light"></div>
      {isMounted && (
        <>
          <NavBar />
          <main className="z-[2] w-full p-4 md:p-6">
            {isChainSupported ? children : <NotSupportedChain />}
          </main>
          <Footer />
        </>
      )}
    </div>
  );
};
