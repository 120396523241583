import { useEffect, useMemo, useState } from "react";
import { useGlobalContext } from "src/_contexts/global/hooks";
import { findTokenByAddress, findTokenBySymbol } from "utils/token";

export const useValidateTokenSymbols = (token0: string, token1: string) => {
  const { tokens } = useGlobalContext();

  const [token0Symbol, setToken0Symbol] = useState(token0);
  const [token1Symbol, setToken1Symbol] = useState(token1);

  useEffect(() => setToken0Symbol(token0), [token0]);
  useEffect(() => setToken1Symbol(token1), [token1]);

  const isToken0Valid = useMemo(
    () =>
      !!tokens.find(
        (token) => token.symbol.toLowerCase() === token0Symbol.toLowerCase()
      ),
    [token0Symbol, tokens]
  );
  const isToken1Valid = useMemo(
    () =>
      !!tokens.find(
        (token) => token.symbol.toLowerCase() === token1Symbol.toLowerCase()
      ),
    [token1Symbol, tokens]
  );

  useEffect(() => {
    if (isToken0Valid && isToken1Valid) return;

    if (!isToken0Valid && isToken1Valid) {
      setToken0Symbol(token1Symbol === "FID" ? "USDC" : "FID");
    } else if (isToken0Valid && !isToken1Valid) {
      setToken1Symbol(token0Symbol === "USDC" ? "FID" : "USDC");
    } else {
      setToken0Symbol("USDC");
      setToken1Symbol("FID");
    }
  }, [isToken0Valid, isToken1Valid, token0Symbol, token1Symbol, tokens]);

  return { token0: token0Symbol, token1: token1Symbol };
};

export const useTokenBySymbol = (tokenSymbol: string) => {
  const { tokens } = useGlobalContext();

  const token = useMemo(
    () => findTokenBySymbol(tokens, tokenSymbol),
    [tokens, tokenSymbol]
  );

  return token;
};

export const useTokenByAddress = (tokenAddress?: string) => {
  const { tokens } = useGlobalContext();

  const token = useMemo(
    () => findTokenByAddress(tokens, tokenAddress),
    [tokens, tokenAddress]
  );

  return token;
};
