import { Button } from "components/Button";
import { appConfig } from "src/configs/app";
import { useSwitchNetwork } from "wagmi";
import { polygon } from "wagmi/chains";
import { Icon } from "_components/Icon";

export const NotSupportedChain = () => {
  const { switchNetwork } = useSwitchNetwork();

  return (
    <div className="mx-auto w-96 rounded-lg border border-error bg-[#fbdddf] p-4">
      <div className="flex flex-col items-center justify-center gap-6 text-error">
        <Icon icon="error" className="h-6 w-6 text-error" />
        <span>Unsupported network</span>
        <Button
          variant="primary-dark"
          onClick={() =>
            switchNetwork && switchNetwork(appConfig.defaultChainId)
          }
        >
          Switch to{" "}
          {appConfig.defaultChainId === polygon.id
            ? "Polygon"
            : "Polygon Mumbai"}
        </Button>
      </div>
    </div>
  );
};
